import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import SocketContext from "../../context/SocketContext";
import { useTranslation } from "react-i18next";

//import api
import { orderPlace, updateTradeAsset } from "../../api/tradeAction";

//import lib
import { encryptObject } from "../../lib/cryptoJS";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

//import validation
import { OrderValidate } from "./validation";
import { Modal } from "react-bootstrap";

function valuetext(value) {
    return `${value}%`;
}

const initialFormValue = {
    quantity: 0,
    price: 0,
    leverage: 0,
    isCloseModal: false,
    isLoss: false,
    isProfit: false,
    takeProfit: 0,
    stopLoss: 0,
};

const Orderplace = (props) => {
    //props
    const { type, pairData, refetch, totalpair } = props;

    //state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [loader, setLoader] = useState(false);
    const [isProfitModal, setisProfitModal] = useState(false);
    const [isLossModal, setisLossModal] = useState(false);
    const [tppnl, settppnl] = useState(0);
    const [slpnl, setslpnl] = useState(0);

    const {
        quantity,
        price,
        leverage,
        isProfit,
        takeProfit,
        stopLoss,
        isLoss,
    } = formValue;

    const socketContext = useContext(SocketContext);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    //redux
    const { firstCurrency, secondCurrency } = useSelector(
        (state) => state.tradeAsset
    );
    const priceConversion = useSelector((state) => state.priceConversion);
    const tradePair = useSelector((state) => state.tradePair);
    const marketPrice = useSelector((state) => state.marketPrice);

    //function
    const handleTotal = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        // if (name == "quantity") {
        //     if (/[^0-9.]/.test(value)) return;
        // }
        let formData = {
            ...formValue,
            ...{ [name]: toFixedDown(value, tradePair?.secondFloatDigit) },
        };
        setFormValue(formData);
        if (value) {
            setLoader(false);
        }
    };

    const handleMax = () => {
        let total = secondCurrency?.stakeBal - secondCurrency?.lockedBal;
        let Markprice =
            type === "buy" ? marketPrice?.askPrice : marketPrice?.bidPrice;
        let price = (total / Markprice) * tradePair?.leverage;
        let formData = {
            ...formValue,
            ...{
                ["quantity"]: toFixedDown(price, tradePair?.secondFloatDigit),
            },
        };
        setFormValue(formData);
    };

    const handleClick = (value) => {
        try {
            let amount = quantity;
            if (value === "sub" && amount > 0) {
                amount--;
            } else if (value === "add") {
                amount++;
            }
            let formData = {
                ...formValue,
                ...{
                    ["quantity"]: toFixedDown(
                        amount,
                        tradePair.secondFloatDigit
                    ),
                },
            };
            setFormValue(formData);
        } catch (err) {
            console.log(err, "errror");
        }
    };

    const handleSubmit = async () => {
        try {
            let reqData = {
                price: price,
                quantity: quantity,
                buyorsell: type,
                pairId: tradePair.pairId,
                newdate: new Date(),
                action: "open",
                isTP: isProfit,
                isSL: isLoss,
                takeProfit,
                stopLoss,
            };
            const validateError = await OrderValidate(reqData);
            if (!isEmpty(validateError)) {
                setLoader(false);
                toastAlert(
                    "error",
                    t(validateError[Object.keys(validateError)[0]]),
                    "limitOrder"
                );
                return;
            }

            let encryptToken = {
                token: encryptObject(reqData),
            };
            setLoader(true);
            let { status, message, error, il8 } = await orderPlace(
                encryptToken
            );
            setTimeout(() => {
                setLoader(false);
            }, 500);
            if (status == "success") {
                let formData = {
                    ...formValue,
                    ...{
                        isLoss: false,
                        isProfit: false,
                        stopLoss: 0,
                        takeProfit: 0,
                    },
                };
                setFormValue(formData);
                toastAlert("success", t(message, il8), "spotOrder");
            } else {
                setLoader(false);
                if (!isEmpty(error)) {
                    toastAlert(
                        "error",
                        error[Object.keys(error)[0]],
                        "limitOrder"
                    );
                    return;
                }
                toastAlert("error", t(message, il8), "spotOrder");
            }
        } catch (err) {
            setLoader(false);
            console.log(err, "errror");
        }
    };

    const handleTPSL = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name == "takeProfit" || name == "stopLoss") {
            if (/[^0-9.]/.test(value)) return;
        }
        let formData = { ...formValue, ...{ [name]: toFixedDown(value, 8) } };
        setFormValue(formData);
        if (value) {
            setLoader(false);
        }

        PnLCalc(value, name);
    };

    const TPCloseModal = () => {
        setisProfitModal(false);
        let formData = {
            ...formValue,
            ...{
                isCloseModal: false,
                isProfit: false,
                takeProfit: 0,
            },
        };
        setFormValue(formData);
        settppnl(0);
    };
    const SLCloseModal = () => {
        setisLossModal(false);
        let formData = {
            ...formValue,
            ...{
                isCloseModal: false,
                isLoss: false,
                stopLoss: 0,
            },
        };
        setFormValue(formData);
        setslpnl(0);
    };

    const handleUpdate = async () => {
        let filter =
            totalpair &&
            totalpair.find(
                (el) => el._id.toString() == tradePair.pairId.toString()
            );
        console.log(isLoss, isProfit, "gfddfgdafsg");
        if (isProfitModal) {
            if (isEmpty(takeProfit) || takeProfit === "" || takeProfit === 0) {
                toastAlert(
                    "error",
                    t("TAKE_PROFIT_REQUIRED"),
                    "marketOrder_tp"
                );
                return;
            }

            if (type == "buy") {
                if (!isEmpty(takeProfit) && takeProfit < filter?.markPrice) {
                    toastAlert(
                        "error",
                        t("TAKE_PROFIT_GREATER"),
                        "marketOrder_tp"
                    );
                    return;
                }
            } else {
                if (!isEmpty(takeProfit) && takeProfit > filter?.markPrice) {
                    toastAlert(
                        "error",
                        t("TAKE_PROFIT_LESSER"),
                        "marketOrder_tp"
                    );
                    return;
                }
            }
        } else if (isLossModal) {
            console.log(stopLoss, "stopLoss");
            if (isEmpty(stopLoss) || stopLoss === "" || stopLoss === 0) {
                toastAlert("error", t("STOP_LOSS_REQUIRED"), "marketOrder_tp");
                return;
            }

            if (type == "buy") {
                if (!isEmpty(stopLoss) && stopLoss > filter?.markPrice) {
                    toastAlert(
                        "error",
                        t("STOP_LOSS_GREATER"),
                        "marketOrder_sl"
                    );
                    return;
                }
            } else {
                if (!isEmpty(stopLoss) && stopLoss < filter?.markPrice) {
                    toastAlert(
                        "error",
                        t("STOP_LOSS_LESSER"),
                        "marketOrder_sl"
                    );
                    return;
                }
            }
        }
        setLoader(false);
        let formData = {
            ...formValue,
            ...{
                isCloseModal: false,
            },
        };
        setFormValue(formData);
        setisLossModal(false);
        setisProfitModal(false);
    };

    const PnLCalc = (lastPrice, name) => {
        try {
            let entryPrice = parseFloat(
                type === "buy" ? marketPrice?.askPrice : marketPrice?.bidPrice
            );
            let exitPrice = parseFloat(lastPrice);
            let qty = parseFloat(quantity);

            if (entryPrice > 0 && exitPrice > 0 && qty > 0) {
                if (type == "buy") {
                    if (name == "takeProfit") {
                        settppnl(qty * (exitPrice - entryPrice));
                    } else {
                        setslpnl(qty * (exitPrice - entryPrice));
                    }
                } else if (type == "sell") {
                    if (name == "takeProfit") {
                        settppnl(qty * (entryPrice - exitPrice));
                    } else {
                        setslpnl(qty * (entryPrice - exitPrice));
                    }
                }
            } else {
                if (name == "takeProfit") {
                    settppnl(0);
                } else {
                    setslpnl(0);
                }
            }
        } catch (err) {
            console.log("err: ", err);
            if (name == "takeProfit") {
                settppnl(0);
            } else {
                setslpnl(0);
            }
        }
    };

    useEffect(() => {
        setFormValue({ ...formValue, ...{ ["leverage"]: pairData?.leverage } });
    }, [type, pairData, quantity, priceConversion, marketPrice]);

    useEffect(() => {
        // socket
        socketContext.socket.on("updateTradeAsset", (result) => {
            updateTradeAsset(dispatch, result);
        });
    }, []);

    useEffect(() => {
        let formData = {
            ...formValue,
            ...{
                isLoss: false,
                isProfit: false,
                stopLoss: 0,
                takeProfit: 0,
            },
        };
        setFormValue(formData);
    }, [type]);

    // console.log(loader, 'marketPricemarketPrice')
    let Balance = secondCurrency.stakeBal - secondCurrency.lockedBal;
    let margin = marketPrice?.markPrice * (quantity / 100);
    return (
        <>
            <div class="form-group">
                <div className="d-flex justify-content-between gap-10 flex-wrap">
                    <label for="amount" class="form-label">
                        {t("QUANTITY")}
                    </label>
                    <label class="form-label">
                        {t("AVAILABLE_BALANCE")}:{" "}
                        <CurrencyFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            value={truncateDecimals(
                                Balance,
                                tradePair.secondFloatDigit
                            )}
                        />{" "}
                        {pairData.secondCurrencySymbol}
                    </label>
                </div>
                <div className="input-group">
                    <button
                        type="button"
                        onClick={() => handleClick("sub")}
                        class="primary_asset_inp"
                    >
                        -
                    </button>
                    <input
                        type="number"
                        className="form-control"
                        name="quantity"
                        value={quantity}
                        onChange={handleTotal}
                        placeholder={t("QUANTITY_PLACEHOLDER")}
                    />
                    <button
                        type="button"
                        onClick={() => handleClick("add")}
                        class="primary_asset_inp"
                    >
                        +
                    </button>
                </div>
                <div className="d-flex justify-content-between gap-10 flex-wrap">
                    <label for="amount" class="form-label"></label>
                    <label className="max_label" onClick={handleMax}>
                        {t("MAX")}
                    </label>
                </div>
            </div>

            <div className="form-group">
                <div className="rcslider_custom">
                    <label>{t("LEVERAGE")}</label>: <span>{leverage}x</span>
                    <Slider
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        valueLabelDisplay="auto"
                        value={leverage}
                        color={"#00b881"}
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="d-flex justify-content-between">
                    <label>{t("TRADE_SIZE")}:</label> <span>{quantity}</span>{" "}
                </div>
                <div className="d-flex justify-content-between">
                    <label>{t("PIP_VALUE")}:</label>{" "}
                    <span>{toFixedDown(quantity / 100, 2)}</span>{" "}
                </div>
                <div className="d-flex justify-content-between">
                    <label>{t("USED_MARGIN")}:</label>{" "}
                    <span>
                        {" "}
                        <CurrencyFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            value={truncateDecimals(margin, 2)}
                        />
                    </span>
                </div>
                <div className="d-flex justify-content-between">
                    <label>Take profit:</label>{" "}
                    <span>
                        {" "}
                        <CurrencyFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            value={truncateDecimals(takeProfit, 2)}
                        />
                    </span>
                </div>
                <div className="d-flex justify-content-between">
                    <label>Stop loss:</label>{" "}
                    <span>
                        {" "}
                        <CurrencyFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            value={truncateDecimals(stopLoss, 2)}
                        />
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-between gap-3">
                <button
                    onClick={() => {
                        if (quantity <= 0) {
                            toastAlert("error", "Enter quantity", "error");
                            return;
                        }
                        setFormValue({
                            ...formValue,
                            ...{ ["isLoss"]: true },
                            ...{ ["stopLoss"]: stopLoss },
                        });
                        setisLossModal(true);
                    }}
                    className="btn btn-danger w-100"
                >
                    Stop Loss
                </button>
                <button
                    class="btn btn-success w-100"
                    onClick={() => {
                        if (quantity <= 0) {
                            toastAlert("error", "Enter quantity", "error");
                            return;
                        }
                        setFormValue({
                            ...formValue,
                            ...{ ["isProfit"]: true },
                            ...{ ["takeProfit"]: takeProfit },
                        });
                        setisProfitModal(true);
                    }}
                >
                    Take Profit
                </button>
            </div>
            <div>
                {loader ? (
                    <button
                        class={
                            type === "buy"
                                ? "primary_btn w-100"
                                : "primary_btn w-100 primary_sell_btn"
                        }
                        disabled={true}
                    >
                        {t("LOADING")} ...
                    </button>
                ) : (
                    <button
                        onClick={handleSubmit}
                        class={
                            type === "buy"
                                ? "primary_btn w-100"
                                : "primary_btn w-100 primary_sell_btn"
                        }
                    >
                        {type === "buy" ? t("BUY") : t("SELL")}
                    </button>
                )}
            </div>
            {/* SL model */}
            <Modal
                show={isLossModal}
                onHide={SLCloseModal}
                backdrop="static"
                keyboard={false}
                size="md"
                centered
            >
                <Modal.Header closeButton onClick={SLCloseModal}>
                    <Modal.Title>
                        <h5>{t("STOP_LOSS")}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div class="form-group">
                            <div className="d-flex justify-content-between gap-10 flex-wrap">
                                <label for="amount" class="form-label">
                                    {t("SP_PRICE")}
                                </label>
                            </div>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="stopLoss"
                                    value={stopLoss}
                                    onChange={handleTPSL}
                                    placeholder={t("STOP_LOSS_PLACEHOLDER")}
                                />
                            </div>
                            <div className="d-flex justify-content-between pt-3 px-5">
                                <label>PnL :</label>{" "}
                                <span className="btn btn-danger">
                                    <CurrencyFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        value={truncateDecimals(slpnl, 4)}
                                    />
                                </span>{" "}
                            </div>
                        </div>
                        <br />
                        <button
                            type="button"
                            className="btn btn-danger btn-outline text-uppercase py-1 ml-2"
                            onClick={SLCloseModal}
                        >
                            {t("CANCEL")}
                        </button>{" "}
                        {loader ? (
                            <button
                                type="button"
                                className="btn btn-success btn-outline text-uppercase py-1 ml-2"
                                disabled={true}
                            >
                                {t("LOADING")}...
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-success btn-outline text-uppercase py-1 ml-2"
                                onClick={handleUpdate}
                            >
                                {t("OK")}
                            </button>
                        )}
                    </form>
                </Modal.Body>
            </Modal>
            {/* TP model */}
            <Modal
                show={isProfitModal}
                onHide={TPCloseModal}
                backdrop="static"
                keyboard={false}
                size="md"
                centered
            >
                <Modal.Header closeButton onClick={TPCloseModal}>
                    <Modal.Title>
                        <h5>{t("TAKE_PROFIT")}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div class="form-group">
                            <div className="d-flex justify-content-between gap-10 flex-wrap">
                                <label for="amount" class="form-label">
                                    {t("TP_PRICE")}
                                </label>
                            </div>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="takeProfit"
                                    value={takeProfit}
                                    onChange={handleTPSL}
                                    placeholder={t("TAKE_PROFIT_PLACEHOLDER")}
                                />
                            </div>
                            <div className="d-flex justify-content-between pt-3 px-5">
                                <label>PnL :</label>{" "}
                                <span className="btn btn-success">
                                    <CurrencyFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        value={truncateDecimals(tppnl, 4)}
                                    />
                                </span>{" "}
                            </div>
                        </div>
                        <br />
                        <button
                            type="button"
                            className="btn btn-danger btn-outline text-uppercase py-1 ml-2"
                            onClick={TPCloseModal}
                        >
                            {t("CANCEL")}
                        </button>{" "}
                        {loader ? (
                            <button
                                type="button"
                                className="btn btn-success btn-outline text-uppercase py-1 ml-2"
                                disabled={true}
                            >
                                {t("LOADING")}...
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-success btn-outline text-uppercase py-1 ml-2"
                                onClick={handleUpdate}
                            >
                                {t("OK")}
                            </button>
                        )}
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Orderplace;
