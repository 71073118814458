import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//import api
import { getTicketList } from "../../api/support";

//import component
import ChatList from "./ChatList";

const TicketList = () => {
  const { t, i18n } = useTranslation();

  // state
  const [ticketList, setTicketList] = useState([]);
  const [sender, setSender] = useState({});
  const [receiver, setReceiver] = useState({});
  const [filter, setFilter] = useState({});

  //function
  const handleSearch = (e) => {
    let { name, value } = e.target;
    setFilter(value);
  };

  const fetchTicketList = async (reqData) => {
    try {
      let filter = {
        search: reqData,
      };
      const { status, result } = await getTicketList(filter);
      if (status == "success") {
        setTicketList(result.ticketList);
        setSender(result.sender);
        setReceiver(result.receiver);
      }
    } catch (err) {
      console.log(err, "errror");
    }
  };

  useEffect(() => {
    fetchTicketList(filter);
  }, [filter]);

  return (
    <>
      <div className="dash_wrapper_flex_right">
        <div className="inner_subtitle_wrap inner_subtitle_wrap_flex">
          <h3>{t("SUPPORT_TICKET_DETAILS")}</h3>
        </div>
        <div className="search_grp">
          <input
            type="text"
            placeholder={t("SEARCH")}
            className="form-control"
            onChange={handleSearch}
          />
          <img
            src={require("../../assets/images/search_icon.png")}
            alt="Search"
            className="img-fluid search_icon"
          />
        </div>
        <div
          className="accordion faq_accordian support_ticket_accordian"
          id={`faq_accordian`}
        >
          {ticketList &&
            ticketList.length > 0 &&
            ticketList.map((item, key) => {
              return (
                <div className="accordion-item">
                  <h2 className="accordion-header" id={`headingOne${key}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseOne${key}`}
                      aria-expanded="false"
                      aria-controls={`collapseOne${key}`}
                    >
                      <label className="ticket_title">
                        {t("SUB")}: <span>{item.categoryName}</span>
                      </label>
                      <div>
                        <label>
                          [ {t("TICKET_ID")}: #{item.tickerId} ]
                        </label>
                        <label>
                          [ {t("STATUS")}:{" "}
                          <span
                            className={
                              item.status == "open" ? "txt_green" : "txt_red"
                            }
                          >
                            {item.status == "open" ? t("OPEN") : t("CLOSED")}
                          </span>{" "}
                          ]
                        </label>
                      </div>
                    </button>
                  </h2>
                  <div
                    id={`collapseOne${key}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`headingOne${key}`}
                    data-bs-parent={`#faq_accordian`}
                  >
                    {/* <div className="accordion-body"> */}
                    <ChatList
                      ticketRecord={item}
                      sender={sender}
                      receiver={receiver}
                    />
                  </div>
                  {/* </div> */}
                </div>
              );
            })}
        </div>
        {ticketList && ticketList.length <= 0 && (
          <div className="text-center">
            <p>{t("TICKET_NOT_FOUND")}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default TicketList;
