import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CopyTradeSetting(props) {
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="dash_wrapper">
                <div className="row">
                  <div className="col-xl-12">
                    <form className="assets_form">
                      <div className="modal_staking_period mb-4">
                        <label className="mb-2">
                          Select Coins/Tokens For Trading:
                        </label>
                        <div className="staking_method_btngrp mb-3 mt-1">
                          <button type="button" className="active">
                            BTC/USDT
                          </button>
                          <button type="button" className="active">
                            ETH/USDT
                          </button>
                          <button type="button">BNB/USDT</button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <label
                                for="withdrawamount"
                                className="form-label"
                              >
                                Maximum Number of Trades
                              </label>
                            </div>
                            <div className="input-group">
                              <span className="primary_asset_inp">
                                <i className="bi bi-dash-lg cursor-pointer"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control primary_asset_inp"
                                placeholder="0"
                                name="price"
                              />
                              <span className="primary_asset_inp">
                                <i className="bi bi-plus-lg cursor-pointer"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <label
                                for="withdrawamount"
                                className="form-label"
                              >
                                Volume Per Trade (USDT)
                              </label>
                            </div>
                            <div className="input-group">
                              <span className="primary_asset_inp">
                                <i className="bi bi-dash-lg cursor-pointer"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control primary_asset_inp"
                                placeholder="0"
                                name="price"
                              />
                              <span className="primary_asset_inp">
                                <i className="bi bi-plus-lg cursor-pointer"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="asset_modal_btn_grp mt-4 mb-4 justify-content-end">
                        <button
                          onClick={handleShow}
                          type="button"
                          className="primary_btn primary_btn_transform"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Copy Trade Settind Confirm */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Copy Trade Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="deposit_asset_details">
            <div>
              <label>Select Coins/Tokens For Trading</label>
              <label>BTC, ETH</label>
            </div>
            <div>
              <label>Maximum Number of Trades</label>
              <label>3</label>
            </div>
            <div>
              <label>Volume Per Trade (USDT)</label>
              <label>10</label>
            </div>
          </div>
          <div className="asset_modal_btn_grp mt-4 mb-4">
            <button
              type="button"
              className="w-100 primary_btn primary_btn_transform"
            >
              {t("CANCEL")}
            </button>
            <button
              type="button"
              className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
            >
              {t("CONFIRM")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
