export default {
  
    "HOMEPAGE_TITLE": "Déverrouillez votre potentiel de gain : Maximisez vos rendements avec <span>{{dashtrades}}</span>",
    "TITLE_CONTENT": "Commencez votre voyage de staking crypto aujourd'hui et regardez vos investissements prospérer. Il est temps de revendiquer votre place dans la révolution crypto !",
    "HOME_BTN1": "Stakez Maintenant",
    "HOME_BTN2": "Inscrivez-vous Maintenant",
    "COUNT1": "Tous les Participants",
    "COUNT2": "Total Gagné",
    "COUNT3": "Changement BTC 24H",
    "HEADER1": "Comment <span>{{dashtrades}}</span> fonctionne",
    "TITLE11": "Stakez Votre Cryptomonnaie",
    "CONTENT11": "Choisissez une cryptomonnaie qui prend en charge le staking, acquérez-la, et transférez-la sur une plateforme ou un portefeuille de staking compatible.",
    "TITLE12": "Participez à Dashtrades",
    "CONTENT12": "Votre cryptomonnaie stakée aide à sécuriser le réseau blockchain et soutient ses opérations. Votre implication dans les activités du réseau vous rapporte des récompenses de staking basées sur la taille de votre mise et les règles du réseau.",
    "TITLE13": "Gérez et Surveillez",
    "CONTENT13": "Examinez régulièrement votre performance de staking, suivez vos récompenses, et restez informé des éventuels changements dans le réseau. Ajustez votre stratégie si nécessaire pour tirer le meilleur parti de votre expérience de staking.",
    "HEADER2": "Gagnez jusqu'à <span>{{dashtrades}}</span> sur vos actifs",
    "TITLE21": "Revenu Passif avec le Staking",
    "CONTENT21": "Explorer le Potentiel de Gain Passif à Travers le Staking Crypto",
    "TITLE22": "Maximiser les Rendements du Staking",
    "CONTENT22": "Stratégies pour Optimiser Vos Gains en Staking Crypto",
    "TITLE23": "Comprendre les Récompenses de Staking",
    "CONTENT23": "Comment le Staking Génère des Profits dans le Monde des Cryptomonnaies",
    "STAKECAL_TITLE": "Voyez ce que vous pouvez Gagner avec <span>{{Staking}}</span>",
    "ASSET": "Actif",
    "AMOUNT": "Montant",
    "AMOUNT_PLACEHOLDER": "Entrez le Montant",
    "STAKING_METHOD": "Méthode de Staking",
    "DEPOSIT_TERM": "Durée du Dépôt",
    "FLEXIBLE": "Flexible",
    "FIXED": "Fixe",
    "STAKE_PERIOD": "En 12 mois, vous aurez un total de",
    "IN": "Dans",
    "YOU_WILL": "vous aurez un total de",
    "FREQUENTLY_ASKED_QUESTIONS": "Questions Fréquemment Posées <span>{{Questions}}</span>",
    "REGISTER": "S'inscrire",
    "LOGIN": "Se Connecter",
    "SIGN_UP": "S'inscrire",
    "SIGN_IN": "Se Connecter",
    "FIRST_NAME": "Prénom",
    "FIRST_NAME_PLACEHOLDER": "Entrez le Prénom",
    "FIRST_NAME_REQUIRED": "Le prénom est requis",
    "LAST_NAME": "Nom de Famille",
    "LAST_NAME_PLACEHOLDER": "Entrez le Nom de Famille",
    "LAST_NAME_REQUIRED": "Le nom de famille est requis",
    "EMAIL": "Adresse Email",
    "EMAIL_PLACEHOLDER": "Entrez l'Adresse Email",
    "EMAIL_REQUIRED": "Le champ Email est requis",
    "EMAIL_INVALID": "Email invalide",
    "EMAIL_NOT_EXISTS": "L'email n'existe pas",
    "EMAIL_EXISTS": "L'email existe déjà",
    "PHONE_NUMBER": "Numéro de Téléphone",
    "PHONE_PLACEHOLDER": "Entrez le Numéro de Téléphone",
    "PHONE_NUMBER_REQUIRED": "Le champ numéro de téléphone est requis",
    "PHONE_NUMBER_INVALID": "Numéro de téléphone invalide",
    "PHONE_NUMBER_EXISTS": "Le numéro de téléphone existe déjà",
    "PASSWORD": "Mot de Passe",
    "PASSWORD_PLACEHOLDER": "Entrez le Mot de Passe",
    "PASSWORD_REQUIRED": "Le champ mot de passe est requis",
    "PASSWORD_INCORRECT": "Mot de passe incorrect",
    "PASSWORD_MIN_MAX": "Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre, un caractère spécial, et avoir entre 6 et 18 caractères",
    "CONFIRM_PASSWORD": "Confirmer le Mot de Passe",
    "CONFIRM_PASSWORD_PLACEHOLDER": "Entrez la Confirmation du Mot de Passe",
    "CONFIRM_PASSWORD_REQUIRED": "Le champ de confirmation du mot de passe est requis",
    "CONFIRM_PASSWORD_MISMATCH": "Le mot de passe et la confirmation du mot de passe doivent être identiques",
    "TERMS_REQUIRED": "Le champ des conditions et de la politique est requis",
    "ALREADY_HAVE_ACCOUNT": "Vous avez déjà un compte",
    "DON'T_HAVE_ACCOUNT": "Vous n'avez pas de compte",
    "REGISTER_CHECKBOX": "Je comprends <Link to='/terms'>{{value1}}</Link> et <Link1 to='/privacy'>{{value2}}</Link1>",
    "OTP": "OTP",
    "OTP_PLACEHOLDER": "Entrez l'OTP",
    "RESENT_OTP": "Renvoyer le Code de Vérification",
    "OTP_VALIDUPTO": "OTP valide jusqu'à",
    "OTP_REQUIRED": "Le champ OTP est requis",
    "OTPSTATUS_REQUIRED": "Le statut de l'OTP est requis",
    "INVALID_OTPSTATUS": "Statut OTP invalide",
    "OTP_SENT_SUCCESS": "OTP envoyé à votre adresse email, veuillez entrer un OTP",
    "EXPIRED_OTP": "OTP expiré",
    "INVALID_OTP": "OTP invalide",
    "SEC": "Sec",
    "REGISTER_SUCCESS": "Votre compte a été enregistré avec succès. Merci !",
    "SOMETHING_WRONG": "Quelque chose s'est mal passé",
    "ERROR_ON_SERVER": "Erreur sur le serveur",
    "ERROR_OCCURED": "Une erreur est survenue",
    "EMAIL_UNVERIFIED": "Votre compte n'est pas encore activé",
    "ACCOUNT_DEACTIVATE": "Votre compte a été désactivé par l'administrateur, veuillez contacter l'administrateur",
    "LOGIN_SUCCESS": "Connecté avec succès",
    "LOGOUT_SUCCESS": "Déconnexion réussie !",
    "FORGOT_PASSWORD": "Mot de passe oublié",
    "FORGOT_PASSWORD_CONTENT": "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse email enregistrée",
    "FORGOT_SUCCESS": "Le lien de confirmation a été envoyé à votre email enregistré",
    "AUTH_TOKEN_REQUIRED": "Le champ de jeton d'authentification est requis",
    "INVALID_TOKEN": "Jeton invalide",
    "RESET_PASSWORD": "Réinitialiser le Mot de Passe",
    "NEW_PASSWORD": "Nouveau Mot de Passe",
    "NEW_PASSWORD_PLACEHOLDER": "Entrez le Nouveau Mot de Passe",
    "NEW_PASSWORD_REQUIRED": "Le champ nouveau mot de passe est requis",
    "NEW_CONFIRM_MISMATCH": "Le nouveau mot de passe et la confirmation doivent être identiques",
    "LING_EXPIRED": "Votre lien a expiré",
    "INVALID_LINK": "Lien invalide",
    "USER_NOT_FOUND": "Utilisateur non trouvé",
    "VERIFICATION_SUCCESS": "Votre processus de vérification est terminé, vous pouvez maintenant changer de mot de passe",
    "RESET_PASSWORD_SUCCESS": "Mot de passe mis à jour avec succès",
    "CHANGE_PASSWORD": "Changer le Mot de Passe",
    "OLD_PASSWORD": "Ancien Mot de Passe",
    "OLD_PASSWORD_PLACEHOLDER": "Entrez l'Ancien Mot de Passe",
    "OLD_PASSWORD_REQUIRED": "Le champ ancien mot de passe est requis",
    "INCORRECT_OLDPASSWORD": "Ancien mot de passe incorrect",
    "PASSWORD_SAME": "Le nouveau mot de passe ne peut pas être identique à l'ancien mot de passe",
    "CHANGE_PASSWORD_SUCCESS": "Mot de passe changé avec succès",
    "CHANGE": "Changer",
    "LAST_LOGIN": "Dernière connexion",
    "EDIT_PROFILE": "Modifier le Profil",
    "UPDATE_PROFILE": "Mettre à Jour le Profil",
    "PROFILE": "Photo de Profil (Optionnel)",
    "INVALID_PROFILE": "Format de profil invalide",
    "TOO_LARGE": "Trop grand",
    "PROFILE_RESTRICTION": "Taille maximale du fichier à télécharger : 2 Mo (seulement Jpeg, Png, Jpg).",
    "ADDRESS": "Adresse",
    "ADDRESS_PLACEHOLDER": "Entrez l'Adresse",
    "ADDRESS_REQUIRED": "Le champ Adresse est requis",
    "CITY": "Ville",
    "CITY_PLACEHOLDER": "Entrez la Ville",
    "CITY_REQUIRED": "Le champ Ville est requis",
    "POSTAL_CODE": "Code Postal",
    "POSTAL_CODE_PLACEHOLDER": "Entrez le Code Postal",
    "POSTAL_CODE_REQUIRED": "Le champ Code Postal est requis",
    "COUNTRY": "Pays",
    "COUNTRY_PLACEHOLDER": "Entrez le Pays",
    "COUNTRY_REQUIRED": "Le champ Pays est requis",
    "UPLOAD_IMAGE": "L'image téléchargée ne peut pas dépasser 2 Mo !",
    "CANCEL": "Annuler",
    "UPDATE": "Mettre à Jour",
    "SUBMIT": "Soumettre",
    "PROFILE_SUCCESS": "Profil mis à jour avec succès",
    "LOGIN_ACTIVITY": "Activité de Connexion",
    "DATE": "Date",
    "BROWSER": "Navigateur",
    "STATUS": "Statut",
    "FAILED": "Échoué",
    "SUCCESS": "Succès",
    "MY_ACCOUNT": "Mon Compte",
    "ACCOUNT_SETTINGS": "Paramètres du Compte",
    "LOGOUT": "Se Déconnecter",
    "NOTIFICATION": "Notifications",
    "NO_NOTIFICATION": "Aucune Notification !",
    "UPDATE_SUCCESS": "Mise à jour réussie",


    "SUPPORT_TICKET": "Ticket de Support",
    "SUBMIT_REQUEST": "Soumettre une Demande",
    "TICKET_FOR": "Ticket Pour",
    "SELECT_CATEGORY": "Sélectionner la Catégorie",
    "DESCRIPTION": "Description",
    "ENTER_MESSAGE": "Entrez le Message",
    "SUPPORT_DESCRIPTION": "Veuillez entrer les détails de votre demande. Un membre de notre équipe de support vous répondra dès que possible.",
    "REQUIRED": "Requis",
    "DOCUMENT_UPLOAD": "Télécharger un Document (Optionnel)",
    "UPLOAD": "Télécharger",
    "UPLOAD_SIZE_RESTRICT": "Taille maximale du fichier à télécharger : 1 Mo (Jpeg, Png, Pdf, Jpg uniquement).",
    "UPLOAD_ERROR": "L'image téléchargée ne peut pas dépasser 1 Mo !",
    "MESSAGE_REQUIRED": "Le champ Message est requis",
    "SUBJECT_REQUIRED": "Le champ Sujet est requis",
    "UPLOAD_ERROR2": "Veuillez choisir parmi les types de fichiers connus jpg, jpeg, png, pdf ou mp4.",
    "TICKET_RAISED_SUCCESS": "Ticket de support créé avec succès",
    "TICKET_ALREADY_CLOSED": "Ticket déjà fermé",
    "MESSAGE_SENT_SUCCESS": "Votre message a été envoyé avec succès",
    "SUPPORT_TICKET_DETAILS": "Détails du Ticket de Support",
    "TICKET_CLOSED_SUCCESS": "Ticket fermé avec succès",
    "TICKET_ID": "ID du Ticket",
    "CREATES_ON": "Créé le",
    "VIEW": "Voir le Fichier",
    "SUPPORT_TEAM": "Équipe de Support",
    "OPEN": "Ouvert",
    "CLOSED": "Fermé",
    "ALL": "Tous",
    "SEARCH": "Recherche",
    "CLOSE_TICKET": "Fermer le Ticket",
    "SUB": "Sous",
    "TICKET_NOT_FOUND": "Aucun Ticket trouvé",

    "WALLET": "Portefeuille",
    "TOTAL_BALANCE": "Solde Total",
    "BALANCE": "Solde",
    "ACTION": "Action",
    "DEPOSIT": "Dépôt",
    "WITHDRAW": "Retirer",
    "EXCHANGE": "Échanger",
    "EXCHANGE_ACCESS": "Accès à l'Échange Bloqué",
    "STAKE": "Stake",
    "STAKE_ACCESS": "Accès au Staking Bloqué",
    "TRADE_ACCESS": "Accès au Trading Bloqué",
    "LIVE_EVENT_ACCESS": "Accès aux Événements en Direct Bloqué",
    "HEDGE_ACCESS": "Accès au Fonds de Couverture Bloqué",
    "HIDE_ZERO_BALANCES": "Masquer les Soldes Nuls",

    "DEPOSIT_TERMS": "Conditions de Dépôt",
    "MINIMUM_DEPOSIT_AMOUNT": "Montant Minimum de Dépôt : ",
    "INVALID_IMAGE": "Image invalide",
    "MAINTENANCE_ERROR": "Maintenance en cours, réessayez plus tard !",
    "DEPOSIT_DESCRIPTION": "Les dépôts inférieurs au montant minimum ne seront pas acceptés et les remboursements ne sont pas autorisés",
    "DEPOSIT_NOTES1": "Envoyez uniquement {{CURRENCY_SYMBOL}} à cette adresse de portefeuille.",
    "DEPOSIT_NOTES2": "Envoyer des pièces ou des jetons autres que {{CURRENCY_SYMBOL}} à ce portefeuille peut entraîner la perte totale de votre dépôt",
    "DEPOSIT_NOTES3": "Veuillez télécharger une capture d'écran ou un hash de votre transaction pour que nous puissions approuver votre transaction",
    "DEPOSIT_CARD": "Dépôt par Carte",
    "DEPOSIT_ADDRESS": "Adresse de Dépôt",
    "MEMO_ADDRESS": "Adresse Mémo",
    "DEPOSIT_IMAGE_RESTRICTION": "Taille maximale du fichier à télécharger : 2 Mo (Jpg, Jpeg, Png, Pdf uniquement).",
    "DEPOSIT_AMOUNT_ERROR": "Veuillez entrer un montant de dépôt supérieur ou égal à {{AMOUNT}}",
    "DEPOSIT_SUCCESS": "Demande de dépôt envoyée avec succès",
    "ASSET_ERROR": "Le champ actif utilisateur est requis",
    "COPIED": "Copié !",


    "WITHDRAW_TERMS": "Conditions de Retrait",
    "MINIMUM_WITHDRAW_AMOUNT": "Le montant minimum de retrait est : ",
    "TERMS1": "Retrait Maximum :",
    "TERMS2": "Frais de Retrait :",
    "RECEIVER_ADDRESS": "Adresse du Récepteur",
    "RECEIVER_ADDRESS_PLACEHOLDER": "Veuillez entrer l'adresse de retrait ici",
    "RECEIVER_ADDRESS_REQUIRED": "Le champ adresse du récepteur est requis",
    "WITHDRAW_AMOUNT": "Montant du Retrait",
    "WITHDRAW_AMOUNT_ERROR": "Veuillez entrer un montant de retrait supérieur ou égal à {{AMOUNT}}",
    "MINIMUM_WITHDRAW_ERROR": "Le montant minimum de retrait doit être supérieur ou égal à {{AMOUNT}}",
    "WITHDRAW_MAXAMOUNT_ERROR": "Le montant du retrait doit être inférieur ou égal à {{AMOUNT}}",
    "WITHDRAW_SUCCESS": "Demande de retrait envoyée avec succès",
    "INSUFFICIENT_WITHDRAW": "Montant de retrait trop bas",
    "WITHDRAW_OTP": "Un OTP a été envoyé avec succès à votre adresse e-mail. Veuillez vérifier votre boîte de réception et utiliser le code fourni pour continuer",

    "FROM_CURRENCY": "De la Devise",
    "TO_CURRENCY": "À la Devise",
    "TO_CURRENCY_REQUIRED": "Le champ de la devise de destination est requis",
    "SELECT_CURRENCY": "Sélectionner la Devise",
    "INVALID_USER": "Utilisateur invalide",
    "INSUFFICIENT_BALANCE": "Solde insuffisant",
    "TRANSFER_SUCCESS": "Transfert réussi",

    "TRANSACTION": "Transactions",
    "DATE": "Date",
    "COIN": "Monnaie",
    "USER_ID": "ID Utilisateur",
    "TYPE": "Type",
    "REASON": "Raison",
    "PENDING": "En Attente",
    "COMPLETED": "Complété",
    "REJECTED": "Rejeté",
    "COIN_DEPOSIT": "Dépôt de Monnaie",
    "COIN_WITHDRAW": "Retrait de Monnaie",
    "COIN_TRANSFER": "Transfert de Monnaie",
    "PROFIT": "Profit",
    "ROWS_PER_PAGE": "Lignes par page",
    "OF": "de",


    "STAKING": "Staking",
    "AVAILABLE_BALANCE": "Solde Disponible",
    "AVAILABLE_BALANCE_USDT": "Solde Disponible USDT",
    "APY": "APY",
    "MAX": "Max",
    "INTEREST": "Intérêt",
    "MINIMUM_STAKE": "Stake Minimum",
    "MAXIMUM_STAKE": "Stake Maximum",
    "WITHDRAWAL_TERM": "Terme de Retrait",
    "REDEMPTION_PERIOD": "Période de Rachat",
    "DURATION": "Durée (Jours)",
    "CONFIRM": "Confirmer",
    "STAKING_PERIOD": "Période de Staking",
    "LOCKED_FOR": "Verrouillé Pour ",
    "AMOUNT_REQUIRED": "Le champ Montant est requis",
    "VALID_AMOUNT": "Veuillez entrer un montant valide",
    "AMOUNT_CAN'T_ZERO": "Le montant doit être supérieur à zéro",
    "MINIMUM_AMOUNT": "Le montant minimum doit être supérieur ou égal à {{AMOUNT}}",
    "MAXIMUM_AMOUNT": "Le montant doit être inférieur ou égal à {{AMOUNT}}",
    "INVALID_STAKING": "Staking invalide",
    "INVALID_CURRENCY": "Devise invalide",
    "INVALID_ASSET": "Actif invalide",
    "DEACTIVE": "Désactivé",
    "NO_RECORD": "Aucun enregistrement",
    "INSUFFICIENT_ASSET": "Il n'y a pas assez d'actifs dans votre solde",
    "STAKING_SUCCESS": "Commande de staking ajoutée avec succès",
    "DURATION_ERROR": "Veuillez choisir la durée en jours",
    "ALREADY_CANCELED": "Déjà annulé",
    "STAKING_HISTORY": "Historique du Staking",
    "TOTAL_EARNED_REDEMPTION": "Rachat Total Gagné",
    "INTEREST_PAID": "Intérêt Payé",
    "ESTIMATED_APY_INTEREST": "Intérêt APY Estimé",
    "ESTIMATED_TOTAL_INTEREST": "Intérêt Total Estimé",
    "ESTIMATED_TOTAL_REDEMPTION": "Rachat Total Estimé",
    "REMAINING_DAYS": "Jours Restants",
    "RELEASE": "Libérer",
    "INTEREST_EARNED": "Intérêt Gagné",
    "INTEREST_IN": "Intérêt en ($)",
    "STAKE_TYPE": "Type de Stake",
    "SUBSCRIPTION": "Abonnement",
    "REDEMPTION_HISTORY": "Historique des Rachats",
    "THERE_NO_RECORD": "Aucun enregistrement à afficher",
    "RELEASE_STAKE": "Êtes-vous sûr de vouloir annuler cette commande ?",
    "OK": "OK",
    "RELEASE_SUCCESS": "Libéré avec succès",


    "TRADE": "Trade",
    "PRO_TRADE": "TRADING PRO",
    "BASIC": "Basique",
    "LOADING": "Chargement",
    "PNL": "PNL",
    "CASH_BALANCE": "Solde en Espèces",
    "EQUITY": "Équité",
    "USED_MARGIN": "Marge Utilisée",
    "AVAILABLE_MARGIN": "Marge Disponible",
    "CRYPTO": "Crypto",
    "CURRENCIES": "Devises",
    "STOCKS": "Actions",
    "INDICES": "Indices",
    "COMMODITIES": "Marchandises",
    "BUY": "Acheter",
    "SELL": "Vendre",
    "QUANTITY": "Quantité",
    "QUANTITY_PLACEHOLDER": "Entrez la Quantité",
    "QUANTITY_REQUIRED": "Le champ Quantité est requis",
    "QUANTITY_ERROR": "La quantité doit être une valeur numérique",
    "QUANTITY_GRATER": "La quantité doit être supérieure à zéro",
    "SIDE_REQUIRED": "Le champ Côté est requis",
    "INVALID_SIDE": "Côté invalide",
    "PAIR_REQUIRED": "Le champ Paire est requis",
    "TRADE_SIZE": "Taille de la Transaction",
    "PIP_VALUE": "Valeur du Pip",
    "ORDER_BOOK": "Carnet de Commandes",
    "OPEN_POSITIONS": "Positions Ouvertes",
    "CLOSED_POSITIONS": "Positions Fermées",
    "CLOSE_ALL_TRADES": "Fermer Toutes les Transactions",
    "PAIR_NAME": "Nom de la Paire",
    "BUYSELL": "Acheter/Vendre",
    "ENTRY_PRICE": "Prix d'Entrée",
    "OPEN_TIME": "Heure d'Ouverture",
    "CLOSE_PRICE": "Prix de Fermeture",
    "CLOSE_TIME": "Heure de Fermeture",
    "CURRENT_PRICE": "Prix Actuel",
    "LEVERAGE": "Effet de Levier",
    "PNL_CLOSE": "PNL/Fermeture",
    "TP": "TP",
    "TAKE_PROFIT": "Prendre Profit",
    "STOP_LOSS": "Stop Loss",
    "SL": "SL",
    "TP_PRICE": "Prendre Profit au Prix",
    "SP_PRICE": "Stop Loss au Prix",
    "TAKE_PROFIT_PLACEHOLDER": "Entrez le Prendre Profit",
    "STOP_LOSS_PLACEHOLDER": "Entrez le Stop Loss",
    "TAKE_PROFIT_REQUIRED": "Le champ Prendre Profit est requis",
    "TAKE_PROFIT_GREATER": "Le Prendre Profit doit être supérieur au prix du marché",
    "TAKE_PROFIT_LESSER": "Le Prendre Profit doit être inférieur au prix du marché",
    "STOP_LOSS_REQUIRED": "Le champ Stop Loss est requis",
    "STOP_LOSS_GREATER": "Le Stop Loss doit être inférieur au prix du marché",
    "STOP_LOSS_LESSER": "Le Stop Loss doit être supérieur au prix du marché",
    "POSITION_NOT_FOUND": "Position non trouvée",
    "TAKE_PROFIT_SUCCESS": "Mise à jour du Prendre Profit réussie",
    "STOP_LOSS_SUCCESS": "Mise à jour du Stop Loss réussie",
    "CLOSED_USER": "Fermé par l'Utilisateur",
    "LOW_MARGIN": "Marge Faible",
    "CLOSE_ORDER": "Fermeture de la commande",
    "CLOSE_CONFIRM": "Êtes-vous sûr de vouloir fermer cette commande ?",
    "TRADE_ALREADY_CLOSED": "Votre commande est déjà fermée",
    "INVALID_SIDE": "Côté invalide",
    "INVALID_PAIR": "Paire invalide",
    "QUANTITY_MINIMUM_ERROR": "La quantité de contrat ne doit pas être inférieure à {{AMOUNT}}",
    "QUANTITY_MAXIMUM_ERROR": "La quantité de contrat ne doit pas être supérieure à {{AMOUNT}}",
    "INSUFFICIENT_TRADE_BALANCE": "En raison d'un solde insuffisant, la commande ne peut pas être passée",
    "ORDER_PLACE_SUCCESS": "Votre commande a été passée avec succès",
    "NO_DATA": "Aucune donnée",
    "ORDER_CLOSE_SUCCESS": "Votre commande a été fermée avec succès",
    "CLOSED_BY_USER": "Fermé par l'Utilisateur",
    "LOW_MARGIN": "Marge Faible",


    "LIVE_EVENTS": "Événements en Direct",
    "NO_LIVE_EVENTS": "Il n'y a aucun événement en direct à afficher",
    "LIVE_ERROR": "Votre navigateur ne prend pas en charge la balise vidéo",

    "HEDGE_FUND": "Fonds de Couverture",
    "TOTAL_HEDGE_FUND": "Valeur Totale du Portefeuille de Fonds de Couverture",
    "PORTFOLIO_PERFORMANCE": "Performance du Portefeuille",
    "PORTFOLIO_ALLOCATION": "Allocation du Portefeuille",
    "NAME": "Nom",
    "PRICE": "Prix",
    "24_CHANGE": "Changement 24H",
    "24_HIGH": "Hauteur 24H",
    "24_LOW": "Bas 24H",
    "TOTAL": "Total",
    "DAY": "Jour",
    "DAYS": "Jours",
    "WEEK": "Semaine",
    "MONTH": "Mois",
    "YEAR": "Année",

    "STAKED_BALANCE": "Solde Staké",
    "ACCOUNT_BALANCE": "Solde du Compte",
    "TOTAL_PL": "P&L Total",


    "INSTRUMENTS": "Instruments",
    "FOREX": "Forex",
    "TRADING": "Trading",
    "PLATFORM": "Plateforme",
    "OPEN_ACCOUNT": "Ouvrir un Compte",
    "MT5_PLATFORM": "Plateforme Mt5",
    "WEBTRADER_PLATFORM": "Plateforme Webtrader",
    "MOBILE_PLATFORM": "Plateforme Mobile",
    "FX_PAYMENTS": "Paiements FX",
    "ACCOUNT_TYPES": "Types de Comptes",
    "FOOTER": "La devise est",
    "FOOTER_CONTENT": "'Dashtrades' et les marques de la famille Dashtrades sont détenues par Dashtrades Corporation. Toutes les autres marques apparaissant sur ce site Web sont la propriété de leurs propriétaires respectifs.",
    "TERMS_OF_SERVICE": "Conditions d'Utilisation",
    "PRIVACY_POLICY": "Politique de Confidentialité",
    "COPY_RIGHTS": "Droits d'Auteur © {{year}} <a href='/'>{{title}}</a> Tous Droits Réservés",
    "SILVER_ACCOUNT": "COMPTE ARGENT",
    "MINI_DEP": "Dépôt Minimum",
    "INCLUDES": "Comprend",
    "CRYPTO_CURRENCIES": "Cryptomonnaies",
    "SAVING_ACCOUNT": "Compte Épargne",
    "DAILY_MARKET_VIEW": "Revue quotidienne du marché",
    "WEEKLY_MARKET_VIEW": "Revue hebdomadaire du marché",
    "WEEKLY_WEBINAR": "Webinaire de trading en direct hebdomadaire",
    "DAILY_POR": "Rapport quotidien de progression du portefeuille",
    "MONTHLY_SESSION": "Session mensuelle avec un comptable certifié",
    "FOREX_LEVERAGE": "jusqu'à {{leverage}} de levier pour le forex",
    "STOCKS_LEVERAGE": "{{leverage}} de levier pour les actions",
    "MARGIN_LOAN": "Prêt sur marge jusqu'à {{percentage}}",
    "PERSONAL_PORFOLIO": "Gestionnaire de portefeuille personnel",
    "ACCESS_TRADING_EVENTS": "Accès à tous les événements de trading spéciaux",
    "DAILY_NEWS": "Actualités quotidiennes",
    "ACCOUNT_MANAGER": "Gestionnaire de compte",
    "WEEKLY_PORFOLIO": "Rapport hebdomadaire de progression du portefeuille",
    "SELECT_SILVER": "Sélectionner Argent",
    "GOLD_ACCOUNT": "COMPTE OR",
    "INVITATION": "Invitation aux événements de trading spéciaux",
    "SILVER_DESCRIPTION2": "Travaillez avec un gestionnaire de portefeuille",
    "24_HOUR": "Surveillance du compte 24/7 par le groupe d'analystes de premier plan",
    "SELECT_GOLD": "Sélectionner Or",
    "PLATINUM_ACCOUNT": "COMPTE PLATINE",
    "WEEKLY_ANALYST": "Hebdomadaire 1 sur 1 avec un analyste de marché",
    "SELECT_PLATINUM": "Sélectionner Platine",
    "DIAMOND_ACCOUNT": "COMPTE DIAMANT",
    "DAILY_ANALYST": "Analyse quotidienne 1 sur 1 avec un analyste de premier plan",
    "CUSTOMIZED_EDUCATION": "Éducation Personnalisée",
    "DAILY_MARKET": "Signaux de marché quotidiens",
    "RESEARCH": "Recherche Approfondie",
    "INVITES_VIP": "Invitations aux événements VIP",
    "SELECT_DIAMOND": "Sélectionner Diamant",
    "CORPORATE_ACCOUNT": "COMPTE CORPORATIF",
    "DAILY_REVIEW": "Revue quotidienne du marché et signaux",
    "UNLIMITED_ACCESS": "Accès illimité aux courtiers",
    "PERSONAL_CHIEF": "Gestionnaire de portefeuille personnel",
    "DAILY_ONE": "Webinaire de trading en direct quotidien 1 sur 1 avec un analyste de premier plan",
    "WEALTH_MANAGER": "Gestionnaire de Patrimoine",
    "SELECT_CORPORATE": "Sélectionner Corporatif"

}