import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// api
import { forgotPassword } from "../../api/users";

// lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  email: "",
};

const ForgotPassForm = () => {
  //hooks
  const { t, i18n } = useTranslation();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const { email } = formValue;

  //function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handleSubmit = async (e) => {
    try {
      const { status, message, errors } = await forgotPassword({ email });
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", t(message), "signup", "TOP_CENTER");
      } else {
        if (errors) {
          setErrors(errors);
          return false;
        }
        toastAlert("error", t(message), "signup", "TOP_CENTER");
      }
    } catch (err) {}
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-6 m-auto">
          <div className="dash_box mb-0">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="inner_title_wrapper mt-2">
                  <h2 className="inner_title">{t("FORGOT_PASSWORD")}</h2>
                  <p>
                    {/* Reset Password link has been send to your registered Email
                    Address */}
                    {t("FORGOT_PASSWORD_CONTENT")}
                  </p>
                </div>
                <form className="auth_form">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      {t("EMAIL")}
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder={t("EMAIL_PLACEHOLDER")}
                      name="email"
                      onChange={handleChange}
                      value={email}
                    />
                    <span className="text-danger">{t(errors?.email)}</span>
                  </div>
                  <div className="text-center mt-4 mb-4">
                    <button
                      type="button"
                      class="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                      onClick={handleSubmit}
                    >
                      {t("SUBMIT")}
                    </button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Link class="ml-auto" to="/login">
                      {t("SIGN_IN")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassForm;
