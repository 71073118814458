import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";

// import components
import PortfolioPerformance from "../components/Hedging/PortfolioPerformance.js";
import PortfolioAllocation from "../components/Hedging/PortfolioAllocation.js";
import TradingViewWidget from "../components/Hedging/header.js";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NewChart from "../components/Hedging/newChart.js";
import ListTable from "../components/Hedging/Table.js";
import { Modal } from "react-bootstrap";
import { toastAlert } from "../lib/toastAlert.js";
//lib
import { toFixedDown, truncateDecimals } from "../lib/roundOf";

//api
import { getPairList, setPairList } from "../api/tradeAction.js";
import { checkHedgeCode } from "../api/users.js";
import isEmpty from "../lib/isEmpty.js";

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export default function Hedging(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //hooks
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    //redux
    const pairListData = useSelector((state) => state.pairList);
    // const { userDashboard } = useSelector((state) => state.siteSetting)

    //state
    const [symbols, setSymbols] = useState([]);
    const [duppairlists, setduppairlists] = useState([]);
    const [piechart, setPieChart] = useState([]);
    const [available, setAvailable] = useState(0);
    const [piecurrency, setPieCurrency] = useState([]);
    const [hedgeCode, sethedgeCode] = useState("");
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState({});

    //function
    const fetchPairList = async () => {
        try {
            const { status, result } = await getPairList();
            if (status == "success") {
                setPairList(result, dispatch);
            }
        } catch (err) {
            console.log(err, "error");
        }
    };

    useEffect(() => {
        fetchPairList();
    }, []);

    useEffect(() => {
        if (pairListData && pairListData.length > 0) {
            let array = [];
            let curArray = [];
            var newarray = [];
            let symbol = [];
            let totalAmount = 0;

            for (var i = 0; i < pairListData.length; i++) {
                if (
                    pairListData[i].hedging == true &&
                    pairListData[i].hedgeStatus == "active"
                ) {
                    // console.log(pairListData[i], "-pairListData[i]")
                    newarray.push(pairListData[i]);
                    if (pairListData[i].type == "crypto") {
                        symbol.push({
                            proName:
                                "BINANCE:" +
                                pairListData[i].firstCurrencySymbol +
                                pairListData[i].secondCurrencySymbol,
                        });
                    }
                    // let color = userDashboard && userDashboard.length > 0 && userDashboard.find((el) => el.currencyId == pairListData[i].firstCurrencyId || el.currencyId == pairListData[i].secondCurrencyId)

                    curArray.push({
                        currency: pairListData[i].firstCurrencySymbol,
                        // colorCode: color != undefined ? color.colorCode : "",
                        _id: pairListData[i]._id,
                        image: pairListData[i].image,
                    });
                    array.push(
                        parseFloat(pairListData[i].hedgeQty) *
                            parseFloat(pairListData[i].markPrice)
                    );
                    totalAmount +=
                        parseFloat(pairListData[i].hedgeQty) *
                        parseFloat(pairListData[i].markPrice);
                }
            }
            setSymbols(symbol);
            setAvailable(toFixedDown(totalAmount, 2));
            setPieChart(array);
            setPieCurrency(curArray);
            setduppairlists(newarray);
        }
    }, [pairListData]);

    const initialPassWordIcon = {
        passwordIcon: false,
    };

    const [passwordIcons, setPasswordIcons] = useState(initialPassWordIcon);
    const { passwordIcon } = passwordIcons;
    const handlePassIcon = (name) =>
        setPasswordIcons({
            ...passwordIcons,
            ...{ [name]: !passwordIcons[name] },
        });

    const handleSubmit = async () => {
        try {
            setLoader(true);
            if (isEmpty(hedgeCode)) {
                setError({ hedgeCode: "Password is required" });
                setLoader(false);
                return;
            }
            const { success, message } = await checkHedgeCode({
                hedgeCode,
            });

            setLoader(true);
            if (success) {
                setLoader(false);
                handleClose();
                toastAlert("success", message, "login");
                localStorage.setItem("isHedgeLogin", true);
            } else {
                setLoader(false);
                toastAlert("error", message, "login");
            }
        } catch (err) {
            setLoader(false);
            console.log(err, "error");
        }
    };

    return (
        <div>
            <ScrollToTopOnMount />
            <NavbarInner />
            <div className="innerpages_wrapper">
                <div className="container-fluid">
                    <div className="dash_wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="dash_box">
                                    <TradingViewWidget symbols={symbols} />
                                </div>
                                <div className="dash_box trade_top_info">
                                    <h3>{t("TOTAL_HEDGE_FUND")}: </h3>
                                    <h3
                                        style={{
                                            color:
                                                available >= 0
                                                    ? "#0dca02"
                                                    : "#dc3545",
                                        }}
                                    >
                                        ${" "}
                                        <CurrencyFormat
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            value={available}
                                        />
                                    </h3>
                                </div>
                                <div className="row">
                                    <div className="col-lg-7 d-flex">
                                        <div className="dash_box w-100">
                                            <div class="orderbook_header">
                                                <h6 class="spot_head">
                                                    {t("PORTFOLIO_PERFORMANCE")}
                                                </h6>
                                            </div>
                                            <NewChart
                                                piecurrency={piecurrency}
                                            />
                                            {/* <PortfolioPerformance /> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-5 d-flex">
                                        <div className="dash_box w-100">
                                            <div class="orderbook_header">
                                                <h6 class="spot_head">
                                                    {t("PORTFOLIO_ALLOCATION")}
                                                </h6>
                                            </div>
                                            <PortfolioAllocation
                                                piechart={piechart}
                                                piecurrency={piecurrency}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {!localStorage.getItem(
                                    "isHedgeLogin"
                                ) && (
                                    <button
                                        type="button"
                                        className="primary_btn my-3"
                                        onClick={handleShow}
                                    >
                                        Login
                                    </button>
                                )}
                                {localStorage.getItem("isHedgeLogin") && (
                                    <div className="dash_box">
                                        <div className="primary_datatable">
                                            <ListTable
                                                duppairlists={duppairlists}
                                                setduppairlists={
                                                    setduppairlists
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t("LOGIN")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="assets_form">
                        <div className="mb-3">
                            <label for="password" className="form-label">
                                {t("PASSWORD")}
                            </label>
                            <div className="asset_deposit_grp">
                                <input
                                    type={!passwordIcon ? "password" : "text"}
                                    className="form-control primary_asset_inp"
                                    name="hedgeCode"
                                    placeholder={t("PASSWORD_PLACEHOLDER")}
                                    value={hedgeCode}
                                    onChange={(e) =>
                                        sethedgeCode(e.target.value)
                                    }
                                />
                                {error && (
                                    <span className={"text-danger"}>
                                        {error && error.hedgeCode}
                                    </span>
                                )}
                                <br />
                                <button
                                    type="button"
                                    className="primary_btn my-3"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                                <span className="withdrawal_type">
                                    <i
                                        class={
                                            passwordIcon
                                                ? "bi bi-eye-fill"
                                                : "bi bi-eye-slash-fill"
                                        }
                                        onClick={() =>
                                            handlePassIcon("passwordIcon")
                                        }
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}
