// helper route
import ConditionRoute from "./components/Route/ConditionRoute";

// import pages
import Home from "./pages/home";
import Wallet from "./pages/wallet";
import Login from "./pages/login";
import Register from "./pages/register";
import ForgotPassword from "./pages/forgot-password";
import Deposit from "./pages/deposit";
import Withdraw from "./pages/withdraw";
import Trade from "./pages/trade";
import ProTrade from "./pages/protrade";
import Notification from "./pages/notification";
import AccountSettings from "./pages/account-settings";
import EmailVerification from "./pages/EmailVerification";
import ResetPassword from "./pages/reset-password";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Staking from "./pages/staking";
import StakingHistoryPage from "./pages/stakeHistory";
import TransactionHistoryPage from "./pages/transaction-history";
import SupportTicket from "./pages/support-ticket";
import Live_Events from "./pages/events.js";
import Hedging from "./pages/hedging.js";
import AccountType from "./pages/account-type";
import CMS_PAGE from "./pages/cms.js";
import CopyTrade from "./pages/copy-trade.js";
import CopyTradeSetting from "./pages/copy-trade-setting.js";

const initialRoutes = [
  // PUBLIC
  { path: "/", type: "public", component: Home },
  {
    path: "email-verification/:authToken",
    type: "public",
    component: EmailVerification,
  },
  { path: "account-types", type: "public", component: AccountType },
  { path: "cms/:tittle", type: "public", component: CMS_PAGE },
  {
    path: "url-login/:authToken",
    type: "public",
    component: EmailVerification,
  },
  {
    path: "user-login/:authToken",
    type: "public",
    component: EmailVerification,
  },
  {
    path: "verification/forgotPassword/:authToken",
    type: "public",
    component: EmailVerification,
  },
  { path: "privacy", type: "public", component: Privacy },
  { path: "terms", type: "public", component: Terms },
  { path: "staking", type: "public", component: Staking },
  { path: "stakinghistory", type: "public", component: StakingHistoryPage },

  {
    path: "transaction-history",
    type: "public",
    component: TransactionHistoryPage,
  },
  { path: "support-ticket", type: "public", component: SupportTicket },

  // AUTH
  { path: "login", type: "auth", component: Login },
  { path: "register", type: "auth", component: Register },
  { path: "register/:refCode", type: "auth", component: Register },
  { path: "forgot-password", type: "auth", component: ForgotPassword },
  { path: "reset-password/:authToken", type: "auth", component: ResetPassword },

  // PRIVATE
  { path: "wallet", type: "private", component: Wallet },
  { path: "deposit/:coin", type: "private", component: Deposit },
  { path: "withdraw/:coin", type: "private", component: Withdraw },
  { path: "trade/:tikerRoot?", type: "private", component: Trade },
  { path: "protrade/:tikerRoot?", type: "private", component: ProTrade },
  { path: "notifications", type: "private", component: Notification },
  { path: "account-settings", type: "private", component: AccountSettings },
  { path: "live-events", type: "private", component: Live_Events },
  { path: "hedging", type: "private", component: Hedging },
  { path: "copy-trade", type: "private", component: CopyTrade },
  { path: "copy-trade-setting", type: "private", component: CopyTradeSetting },
];

const routes = initialRoutes.map(({ type, component: Component, ...rest }) => {
  return {
    element: (
      <ConditionRoute type={type}>
        <Component />
      </ConditionRoute>
    ),
    ...rest,
  };
});

export default routes;
