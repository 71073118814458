import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner";
import Navbar from "../components/Navbar.js";
import { useTranslation } from "react-i18next";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;

}




export default function AccountType(props) {

  const { t, i18n } = useTranslation()


  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="inner_title_wrapper">
            <h2 className="inner_title">{t("ACCOUNT_TYPES")}</h2>
          </div>
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-xl-12">
                <div class="snip1517">
                  <div class="plan">
                    <div>
                      <header>
                        <h4 class="plan-title">{t("SILVER_ACCOUNT")}</h4>
                        <div class="plan-cost"><span class="plan-price">£ 10,000</span></div>
                      </header>
                      <ul class="plan-features">
                        <li><i class="ion-android-remove"> </i>{t("MINI_DEP")}</li>
                        <li><i class="ion-android-remove"> </i>{t("INCLUDES")}</li>
                        <li><i class="ion-android-remove"> </i>
                          {t("FOREX_LEVERAGE", {
                            leverage: "1:100",
                          })}
                        </li>
                        <li><i class="ion-android-remove"> </i> {t("STOCKS_LEVERAGE", {
                          leverage: "1:1",
                        })}</li>
                        <li><i class="ion-android-remove"> </i>{t("CRYPTO_CURRENCIES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("COMMODITIES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("INDICES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("SAVING_ACCOUNT")}</li>
                        <li><i class="ion-android-remove"> </i>{t("DAILY_NEWS")}</li>
                        <li><i class="ion-android-remove"> </i>{t("WEEKLY_MARKET_VIEW")}</li>
                        <li><i class="ion-android-remove"> </i>{t("ACCOUNT_MANAGER")}</li>
                        <li><i class="ion-android-remove"> </i>{t("WEEKLY_PORFOLIO")}</li>
                      </ul>
                    </div>
                    <div class="plan-select"><a href="#" className="primary_btn">{t("SELECT_SILVER")}</a></div>
                  </div>
                  <div class="plan">
                    <div>
                      <header>
                        <h4 class="plan-title">{t("GOLD_ACCOUNT")}</h4>
                        <div class="plan-cost"><span class="plan-price">£ 50,000</span></div>
                      </header>
                      <ul class="plan-features">
                        <li><i class="ion-android-remove"> </i>{t("MINI_DEP")}</li>
                        <li><i class="ion-android-remove"> </i>{t("INCLUDES")}</li>
                        <li><i class="ion-android-remove"> </i> {t("FOREX_LEVERAGE", {
                          leverage: "1:200",
                        })}</li>
                        <li><i class="ion-android-remove"> </i>{t("STOCKS_LEVERAGE", {
                          leverage: "1:2",
                        })}</li>
                        <li><i class="ion-android-remove"> </i>{t("CRYPTO_CURRENCIES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("COMMODITIES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("INDICES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("SAVING_ACCOUNT")}</li>
                        <li><i class="ion-android-remove"> </i>{t("MARGIN_LOAN", {
                          percentage: "25%",
                        })}</li>
                        <li><i class="ion-android-remove"> </i>{t("INVITATION")} </li>
                        <li><i class="ion-android-remove"> </i>{t("DAILY_MARKET_VIEW")}</li>
                        <li><i class="ion-android-remove"> </i>{t('DAILY_POR')}</li>
                        <li><i class="ion-android-remove"> </i>{t("SILVER_DESCRIPTION2")}</li>
                        <li><i class="ion-android-remove"> </i>{t("MONTHLY_SESSION")}</li>
                        <li><i class="ion-android-remove"> </i>{t("24_HOUR")}</li>
                      </ul>
                    </div>
                    <div class="plan-select"><a href="#" className="primary_btn">{t("SELECT_GOLD")}</a></div>
                  </div>
                  <div class="plan">
                    <div>
                      <header>
                        <h4 class="plan-title">
                          {t("PLATINUM_ACCOUNT")}
                        </h4>
                        <div class="plan-cost"><span class="plan-price">£ 100,000</span></div>
                      </header>
                      <ul class="plan-features">
                        <li><i class="ion-android-remove"> </i>{t("MINI_DEP")}</li>
                        <li><i class="ion-android-remove"> </i>{t("INCLUDES")} </li>
                        <li><i class="ion-android-remove"> </i>   {t("FOREX_LEVERAGE", {
                          leverage: "1:200",
                        })}
                        </li>
                        <li><i class="ion-android-remove"> </i>{t("STOCKS_LEVERAGE", {
                          leverage: "1:2",
                        })}</li>
                        <li><i class="ion-android-remove"> </i>{t("CRYPTO_CURRENCIES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("COMMODITIES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("INDICES")}</li>
                        <li><i class="ion-android-remove"> </i>{t("SAVING_ACCOUNT")}</li>
                        <li><i class="ion-android-remove"> </i> {t("MARGIN_LOAN", {
                          percentage: "50%",
                        })}</li>
                        <li><i class="ion-android-remove"> </i>{t("ACCESS_TRADING_EVENTS")}</li>
                        <li><i class="ion-android-remove"> </i>{t("DAILY_MARKET_VIEW")}</li>
                        <li><i class="ion-android-remove"> </i>{t('DAILY_POR')}</li>
                        <li><i class="ion-android-remove"> </i>{t("PERSONAL_PORFOLIO")}</li>
                        <li><i class="ion-android-remove"> </i>{t("WEEKLY_WEBINAR")}</li>
                        <li><i class="ion-android-remove"> </i>{t("WEEKLY_ANALYST")}</li>
                        <li><i class="ion-android-remove"> </i>{t("MONTHLY_SESSION")}</li>
                        <li><i class="ion-android-remove"> </i>{t("24_HOUR")}</li>
                      </ul>
                    </div>
                    <div class="plan-select"><a href="#" className="primary_btn">{t("SELECT_PLATINUM")} </a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
