// import config
import axios, { handleResp } from "../config/axios";

export const getCPList = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/get-cp-list`,
            params: data,
        });
        return {
            success: respData.data.success,
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            success: false,
            loading: false,
            message: err.response.data.message,
            result: [],
        };
    }
};
