import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Trans, useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
// api
import { createUser } from "../../api/users";

// lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
import { error } from "jquery";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
} from "../../lib/localStorage";

const initialFormValue = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  referralCode: "",
  referralCodeDisabled: false,
  isTerms: "",
  otpStatus: false,
  otp: "",
  phoneNo: "",
  phoneCode: "",
  passwordIcon: false,
  confirmPasswordIcon: false,
};
const timerInitialValue = {
  isLoading: false,
  type: "send", // send or resend,
  timer: 120, //sec,
  isDisable: false,
  timerStart: false,
};

const RegisterForm = () => {
  const { refCode } = useParams();
  const { t, i18n } = useTranslation();

  //hooks
  const navigate = useNavigate();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [timerDetail, setTimerDetail] = useState(timerInitialValue);
  const {
    email,
    password,
    confirmPassword,
    referralCode,
    isTerms,
    otpStatus,
    otp,
    phoneNo,
    phoneCode,
    passwordIcon,
    confirmPasswordIcon,
    firstName,
    lastName,
    referralCodeDisabled,
  } = formValue;

  //function
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name == "firstName" || name == "lastName") {
      if (/[^a-zA-Z ]/.test(value)) return;
    }
    if (name == "phoneNo" || name == "phoneCode") {
      if (/[^0-9]/.test(value)) return;
    }
    setFormValue({
      ...formValue,
      ...{ [name]: name != "isTerms" ? value : checked },
    });
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;

    if (!isValidPhoneNumber("+" + value) && value.length > 3) {
      setErrors({ ...errors, ...{ phoneNo: t("PHONE_NUMBER_INVALID") } });
    } else {
      setErrors({ ...errors, ...{ phoneNo: "" } });
    }
    if (isEmpty(value)) {
      setErrors({ ...errors, ...{ phoneNo: "" } });
    }
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } };
    }
    setFormValue(formData);
  };

  const handlePassIcon = (name) =>
    setFormValue({ ...formValue, ...{ [name]: !formValue[name] } });

  const handleSubmit = async (e) => {
    try {
      if (!isEmpty(phoneCode) || !isEmpty(phoneCode)) {
        if (!isValidPhoneNumber("+" + phoneCode + phoneNo)) {
          setErrors({ ...errors, ...{ phoneNo: t("PHONE_NUMBER_INVALID") } });
          return;
        }
      }
      setLoader(true);
      const { status, loading, message, error } = await createUser(formValue);
      setLoader(loading);
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", t(message), "signup", "TOP_CENTER");
        navigate("/login");
      } else if (status === "OTP") {
        setFormValue({ ...formValue, ...{ otpStatus: true } });
        setErrors({});
        setTimerDetail({
          ...timerDetail,
          ...{
            isLoading: false,
            isDisable: true,
            timer: timerDetail.timer - 1,
            timerStart: true,
          },
        });
        toastAlert("success", t(message), "signup", "TOP_CENTER");
        localStorage.removeItem("refCode");
      } else {
        if (error) {
          setErrors(error);
          return false;
        }
        toastAlert("error", t(message), "signup", "TOP_CENTER");
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleresendotp = async (e) => {
    try {
      setTimerDetail({
        ...timerDetail,
        ...{ isLoading: true, isDisable: true },
      });
      let req = { ...formValue, ...{ otpStatus: false } };
      setLoader(true);
      const { status, loading, message, error } = await createUser(req);
      setLoader(loading);
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", t(message), "signup", "TOP_CENTER");
        navigate("/login");
      } else if (status === "OTP") {
        setFormValue({ ...formValue, ...{ otpStatus: true } });
        setErrors({});
        setTimerDetail({
          ...timerDetail,
          ...{
            isLoading: false,
            isDisable: true,
            timer: timerDetail.timer - 1,
            timerStart: true,
            type: "resend",
          },
        });
        toastAlert("success", t(message), "signup", "TOP_CENTER");
        localStorage.removeItem("refCode");
      } else {
        setTimerDetail({
          ...timerDetail,
          ...{ isLoading: false, isDisable: false },
        });
        if (error) {
          setErrors(error);
          return false;
        }
        toastAlert("error", t(message), "signup", "TOP_CENTER");
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    if (timerDetail.timer > 0 && timerDetail.timerStart === true) {
      const intervalId = setInterval(() => {
        setTimerDetail({
          ...timerDetail,
          ...{ timer: timerDetail.timer - 1 },
        });
      }, 2000);

      return () => clearInterval(intervalId);
    } else if (timerDetail.timer === 0 && timerDetail.timerStart === true) {
      setTimerDetail({
        ...timerDetail,
        ...{
          timer: 120,
          timerStart: false,
          isDisable: false,
        },
      });
    }
  }, [timerDetail]);

  useEffect(() => {
    if (!isEmpty(refCode)) {
      // Example usage:
      const key = "refCode";
      const value = refCode;
      const expirationMinutes = 10;
      setLocalStorageWithExpiration(key, value, expirationMinutes);
      navigate("/register");
    }
  }, [refCode]);

  useEffect(() => {
    const retrievedValue = getLocalStorageWithExpiration("refCode");
    if (!isEmpty(retrievedValue)) {
      setFormValue({
        ...formValue,
        ...{ ["referralCode"]: retrievedValue, ["referralCodeDisabled"]: true },
      });
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-6 m-auto">
          <div className="dash_box mb-0">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="inner_title_wrapper mt-2">
                  <h2 className="inner_title">{t("REGISTER")}</h2>
                </div>
                <form className="auth_form">
                  <div class="mb-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          {t("FIRST_NAME")}
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder={t("FIRST_NAME_PLACEHOLDER")}
                          name="firstName"
                          onChange={handleChange}
                          value={firstName}
                        />
                        <span className="text-danger">
                          {t(errors?.firstName)}
                        </span>
                      </div>
                      <div className="col-lg-6">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          {t("LAST_NAME")}
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder={t("LAST_NAME_PLACEHOLDER")}
                          name="lastName"
                          onChange={handleChange}
                          value={lastName}
                        />
                        <span className="text-danger">
                          {t(errors?.lastName)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      {t("EMAIL")}
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder={t("EMAIL_PLACEHOLDER")}
                      name="email"
                      onChange={handleChange}
                      value={email}
                    />
                    <span className="text-danger">{t(errors?.email)}</span>
                  </div>
                  <div class="mb-3 phone_num">
                    <label for="exampleFormControlInput1" class="form-label">
                      {t("PHONE_NUMBER")}
                    </label>
                    <PhoneInput
                      placeholder={t("PHONE_PLACEHOLDER")}
                      value={phoneCode + phoneNo}
                      onChange={handlePhoneNumber}
                      specialLabel={false}
                      enableSearch={true}
                    />
                    <span className={"text-danger"}>
                      {errors && t(errors.phoneNo)}
                    </span>
                  </div>

                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      {t("PASSWORD")}
                    </label>
                    <div className="asset_deposit_grp">
                      <input
                        type={!passwordIcon ? "password" : "text"}
                        className="form-control primary_asset_inp"
                        placeholder={t("PASSWORD_PLACEHOLDER")}
                        name="password"
                        onChange={handleChange}
                        value={password}
                      />
                      <span className="withdrawal_type">
                        <i
                          class={
                            passwordIcon
                              ? "bi bi-eye-fill"
                              : "bi bi-eye-slash-fill"
                          }
                          onClick={() => handlePassIcon("passwordIcon")}
                        ></i>
                      </span>
                    </div>
                    <span className="text-danger">{t(errors?.password)}</span>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      {t("CONFIRM_PASSWORD")}
                    </label>
                    <div className="asset_deposit_grp">
                      <input
                        type={!confirmPasswordIcon ? "password" : "text"}
                        className="form-control primary_asset_inp"
                        placeholder={t("CONFIRM_PASSWORD_PLACEHOLDER")}
                        name="confirmPassword"
                        onChange={handleChange}
                        value={confirmPassword}
                      />
                      <span className="withdrawal_type">
                        <i
                          class={
                            confirmPasswordIcon
                              ? "bi bi-eye-fill"
                              : "bi bi-eye-slash-fill"
                          }
                          onClick={() => handlePassIcon("confirmPasswordIcon")}
                        ></i>
                      </span>
                    </div>
                    <span className="text-danger">
                      {t(errors?.confirmPassword)}
                    </span>
                  </div>
                  {/* <div class="mb-3">
                    <label for="inputPassword" class="form-label">
                      Referral (Optional)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="referral"
                      placeholder="Enter Referral ID"
                      name="referralCode"
                      disabled={referralCodeDisabled}
                      onChange={handleChange}
                      value={referralCode}
                    />
                    <span className="text-danger">{errors?.referralCode}</span>
                  </div> */}
                  {otpStatus == true && (
                    <div class="mb-3">
                      <label for="inputPassword" class="form-label">
                        {t("OTP")}
                      </label>
                      <div className="asset_deposit_grp">
                        <input
                          type="text"
                          class="form-control"
                          id="otp"
                          placeholder={t("OTP_PLACEHOLDER")}
                          name="otp"
                          onChange={handleChange}
                          value={otp}
                        />

                        {timerDetail.timer !== 0 &&
                        timerDetail.timer !== 120 ? (
                          <span className="withdrawal_type">
                            {`${timerDetail.timer} ${t("SEC")}`}
                          </span>
                        ) : (
                          <span
                            className="withdrawal_type"
                            onClick={(e) =>
                              !timerDetail.isDisable && handleresendotp(e)
                            }
                          >
                            {t("RESENT_OTP")}
                          </span>
                        )}
                      </div>
                      <span className="text-danger">{t(errors?.otp)}</span>
                    </div>
                  )}
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      name="isTerms"
                      onChange={handleChange}
                      checked={isTerms}
                    />
                    <label class="form-check-label" for="inlineCheckbox1">
                      <Trans
                        i18nKey="REGISTER_CHECKBOX"
                        components={{
                          Link: <Link to="/terms"></Link>,
                          Link1: <Link to="/privacy"></Link>,
                        }}
                        values={{
                          value1: "Terms of Use",
                          value2: "Privacy Policy",
                        }}
                      />
                    </label>
                  </div>
                  <span className="text-danger">{t(errors?.isTerms)}</span>
                  <br />
                  <div className="text-center mt-4 mb-4">
                    <button
                      type="button"
                      class="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                      onClick={handleSubmit}
                      disabled={loader}
                    >
                      {loader && <i class="fas fa-spinner fa-spin"></i>}
                      {t("REGISTER")}
                    </button>
                  </div>
                  <p class="form_note text-center mt-3">
                    {t("ALREADY_HAVE_ACCOUNT")}?{" "}
                    <Link to="/login">{t("SIGN_IN")}</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
