import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { getCPList } from "../api/copytrade.js";
import { capitalize } from "../lib/stringCase.js";

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

var initialValue = {
    page: 1,
    search: "",
    limit: 10,
};
export default function CopyTrade(props) {
    const { t, i18n } = useTranslation();

    const [chartData] = useState({
        series: [
            {
                name: "Revenue",
                data: [10, 40, 35, 54, 60, 60, 70, 91, 22],
            },
        ],
        options: {
            chart: {
                type: "area",
                height: 100,
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                ],
                labels: { show: false },
                axisBorder: { show: false },
                axisTicks: { show: false },
            },
            yaxis: {
                labels: { show: false },
            },
            grid: { show: false },
            tooltip: { enabled: false },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 2,
                colors: ["#97fce4"],
            },
            fill: {
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    gradientToColors: ["#97fce4"],
                    opacityFrom: 0.7,
                    opacityTo: 0,
                    stops: [0, 100],
                },
            },
        },
    });

    //state
    const [data, setData] = useState([]);
    const [count, setCount] = useState();
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = React.useState(initialValue);
    let { page, search, limit } = filters;

    //function
    const fetchList = async ({ page, search, limit }) => {
        setLoading(true);
        let reqData = {
            page: parseInt(page),
            limit: parseInt(limit),
            search: search,
        };
        let { success, result } = await getCPList(reqData);
        setLoading(false);
        if (success) {
            setCount(result.count);
            setData(result.data);
        }
    };

    const loadMore = () => {
        setFilters((prev) => ({ ...prev, page: prev.page + 1 }));
        fetchList({ page, search, limit });
    };

    useEffect(() => {
        fetchList({ page, search, limit });
    }, [filters]);

    return (
        <div>
            <ScrollToTopOnMount />
            <NavbarInner />
            <div className="innerpages_wrapper">
                <div className="container">
                    <div className="dash_wrapper">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="staking_top_flex">
                                    <ul
                                        class="nav nav-pills custom_nav_tabs"
                                        id="pills-tab"
                                        role="tablist"
                                    >
                                        <li
                                            class="nav-item"
                                            role="presentation"
                                        >
                                            <button
                                                class={`nav-link active`}
                                                id="pills-pl-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-pl"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-pl"
                                                aria-selected="true"
                                            >
                                                P&L%
                                            </button>
                                        </li>
                                        {/* <li class="nav-item" role="presentation">
                      <button
                        class={`nav-link`}
                        id="pills-days-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-days"
                        type="button"
                        role="tab"
                        aria-controls="pills-days"
                        aria-selected="false"
                      >
                        Win Days
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class={`nav-link`}
                        id="pills-new-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-new"
                        type="button"
                        role="tab"
                        aria-controls="pills-new"
                        aria-selected="false"
                      >
                        Newest
                      </button>
                    </li> */}
                                    </ul>
                                    <div className="search_grp">
                                        <input
                                            type="text"
                                            placeholder={t("SEARCH")}
                                            className="form-control"
                                        />
                                        <img
                                            src={require("../assets/images/search_icon.png")}
                                            alt="Search"
                                            className="img-fluid search_icon"
                                        />
                                    </div>
                                </div>

                                <div class="tab-content" id="pills-tabContent">
                                    <div
                                        class="tab-pane fade show active"
                                        id="pills-pl"
                                        role="tabpanel"
                                        aria-labelledby="pills-pl-tab"
                                    >
                                        <div className="row">
                                            {data && data.length > 0 ? (
                                                <>
                                                    {data.map(
                                                        (items, index) => (
                                                            <div className="col-md-6 col-xl-4 col-lg-4">
                                                                <div className="dash_box dash_box_right copy_trade_box">
                                                                    <div className="dash_box_right_top flex-dashbox-top">
                                                                        <div className="crypto-img-name">
                                                                            <img
                                                                                src={require("../assets/images/logo_icon.png")}
                                                                                alt="Crypto"
                                                                                className="img-fluid"
                                                                                style={{
                                                                                    width: "50px",
                                                                                }}
                                                                            />
                                                                            <div>
                                                                                <h4>
                                                                                    {capitalize(items?.groupName
                                                                                )}
                                                                                </h4>
                                                                                <div className="d-flex align-items-center gap-2">
                                                                                    <i class="bi bi-people-fill"></i>
                                                                                    <span>
                                                                                        <span className="txt_green">
                                                                                            5
                                                                                        </span>{" "}
                                                                                        /
                                                                                        100
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <a href="/copy-trade-setting">
                                                                            <button
                                                                                type="button"
                                                                                className="primary_btn"
                                                                            >
                                                                                Follow
                                                                            </button>
                                                                        </a>
                                                                    </div>
                                                                    <div className="dash_box_right_bottom">
                                                                        <div>
                                                                            <label>
                                                                                Total
                                                                                Days
                                                                            </label>
                                                                            <label>
                                                                                196
                                                                            </label>
                                                                        </div>
                                                                        <div>
                                                                            <label>
                                                                                Win
                                                                                Days
                                                                            </label>
                                                                            <label className="txt_green">
                                                                                10
                                                                            </label>
                                                                        </div>
                                                                        <div>
                                                                            <label>
                                                                                P&L
                                                                            </label>
                                                                            <label className="txt_green">
                                                                                12.14
                                                                                USDT
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <h2 className="text-center txt_green">
                                                                        3.81 %
                                                                    </h2>
                                                                    <div id="chart">
                                                                        <Chart
                                                                            options={
                                                                                chartData.options
                                                                            }
                                                                            series={
                                                                                chartData.series
                                                                            }
                                                                            type="area"
                                                                            height={
                                                                                100
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <h6 className="text-center gap-2">
                                                    No Records found
                                                </h6>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
